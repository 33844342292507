import React, { useEffect, useState } from "react";

const RecommendationsTab = ({
  recommendation,
  isBot,
  query,
  timestamp,
  sendtoAPI,
  setMessages,
  recommendedShow,
  setInputBox,
}) => {
  const [recom, setRecom] = useState(false);

  useEffect(() => {
    if (localStorage.getItem("session-token") !== null && recommendedShow) {
      setRecom(true);
      setInputBox(false)
    } else if (localStorage.getItem("session-token") === null) {
      setRecom(true);
    }
  }, [recommendation]);

  const handleRecommendationClick = (index) => {
    // setInputBox(true)
    const endpoint = "ask";
    const data = { question: index };
    setMessages((prevMessages) => [
      ...prevMessages,
      {
        query: index,
        isBot: false,
        timestamp: new Date().toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
          hour12: false,
        }),
      },
    ]);
    setInputBox(true);
    sendtoAPI(endpoint, data);
  };
  return (
    <>
      {recom && (
        <div className="recommendationHolder">
          {recommendation != null &&
            Object.keys(recommendation).map((recommendationItem, index) => (
              <button
                key={"chat" + index}
                className="recommendationItem"
                style={{ width: "170px", color: "black" }}
                onClick={() =>
                  handleRecommendationClick(recommendation[recommendationItem])
                }
              >
                {recommendation[recommendationItem]}
              </button>
            ))}
        </div>
      )}
    </>
  );
};

export default RecommendationsTab;
