import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

const LoginModule = ({
  sendtoAPI,
  questionAsked,
  setUseMessage,
  setIsTnc,
  baseurl,
}) => {
  const [credentials, setCredentials] = useState({
    mobile_number: "",
    password: "",
  });

  const [show, setShow] = useState("none");

  const handleClose = () => {
    setShow("none");
    const endpoint = "ask";
    const message = { question: questionAsked };
    sendtoAPI(endpoint, message);
  };
  const handleShow = () => setShow(true);
  const [userId, setUserId] = useState({
    input: false,
    spin: false,
  });
  const [otpView, setOtpView] = useState({
    input: false,
    spin: false,
  });

  const [inputEmailValue, setInputEmailValue] = useState("");
  const [inputPasswordValue, setInputPasswordValue] = useState("");
  const [otpBox, setOtpBox] = useState("none");
  const getCurrentTimestamp = () => new Date();
  const [msgDisplay, setMsgDisplay] = useState("");
  const [otpDisplay, setOtpDisplay] = useState("");
  const [sessionCookies, setSessionCookie] = useState("");
  let sessionCookie = "";

  const handleGenerateOTP = async () => {
    setUserId({ spin: true, input: false });
    const bodyContent = JSON.stringify({
      user_input: credentials.mobile_number,
    });

    try {
      const response = await fetch(`${baseurl}generate_otp`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: bodyContent,
      });

      if (!response.ok) {
        setMsgDisplay("Network response was not ok");
        setUserId({ spin: false, input: true });
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      console.log(data);
      // console.log(" generate", data);
      if (
        data.message.includes("OTP generated") ||
        data.message.includes("OTP Generated") ||
        data.message.includes("otp generated") ||
        data.message.includes("otp Generated")
      ) {
        // console.log("object-ID", data.session_id);
        setMsgDisplay(data.message);
        localStorage.setItem("sessionIdGen", data.session_id);
        // console.log("sessionGen", localStorage.getItem("sessionIdGen"));
        setUserId({ spin: false, input: false });
        setOtpBox("block");
      } else {
        setMsgDisplay(data.message);
        setUserId({ spin: false, input: true });
      }
    } catch (error) {
      console.error("Error sending message to Azure Function:", error);
    }
  };

  const handleValidateOTP = async () => {
    let sessionId = localStorage.getItem("sessionIdGen");
    setOtpView({ spin: true, input: false });

    try {
      let headersList = {
        "Content-Type": "application/json",
      };

      let bodyContent = JSON.stringify({
        otp: `${credentials.password}`,
        session_id: `${sessionId}`,
      });

      let response = await fetch(baseurl + "validate_otp", {
        method: "POST",
        body: bodyContent,
        headers: headersList,
      });
      // console.log("objectReaspon", response);
      if (!response.ok) {
        setCredentials({
          mobile_number: "",
          password: "",
        });
        setOtpDisplay("Incorrect OTP.");
        setUserId({ spin: false, input: false });
        setOtpView({ spin: false, input: true });
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      console.log("otp data", data);
      setOtpDisplay(data.message);
      if (data.message === "OTP verified Successfully") {
        if (data.access_token) {
          sessionStorage.setItem("isLogin", true);
          setOtpView({ spin: false, input: false });
          setOtpDisplay(data.message);
          localStorage.setItem("session-token", data.access_token);
          setShow("block");
        }
      } else {
        setOtpView({ spin: false, input: true });
        setCredentials({
          mobile_number: "",
          password: "",
        });
        setOtpDisplay(data.message);
      }
    } catch (error) {
      console.warn(error);
    }
  };

  useEffect(() => {
    setUseMessage({
      input: false,
      spin: true,
    });
  }, []);
  const handleEmailOnChange = (e) => {
    setInputEmailValue(e.target.value);
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
    setOtpBox("none");
    setMsgDisplay();
    setOtpView({
      input: !!e.target.value,
      spin: false,
    });
    setUserId({ input: !!e.target.value, spin: false });
  };
  const handlePasswordOnChange = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
    setInputPasswordValue(e.target.value);
    setOtpDisplay();
    setOtpView({ input: !!e.target.value, spin: false });
  };

  var modal = document.getElementById("myModal");

  // Get the button that opens the modal
  var btn = document.getElementById("myBtn");

  // Get the <span> element that closes the modal
  var span = document.getElementsByClassName("close")[0];

  // When the user clicks the button, open the modal
  // btn.onclick = function() {
  //   modal.style.display = "block";
  // }

  // When the user clicks on <span> (x), close the modal
  // span.onclick = function() {
  //   modal.style.display = "none";
  // }

  return (
    <>
      <div
        className="text_wrapper p-2"
        style={{ marginRight: "100px", width: "350px" }}
      >
        {/* <button
          id="myBtn"
          onClick={() => {
            setShow("block");
          }}
        >
          Open Modal
        </button> */}

        <p className="loginAlert">
          To proceed with your request, please login:
        </p>
        <div className="logincontainer">
          <div className="logininputholders">
            {userId.spin && (
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.14,1.16a11,11,0,0,0-9,8.92A1.59,1.59,0,0,0,2.46,12,1.52,1.52,0,0,0,4.11,10.7a8,8,0,0,1,6.66-6.61A1.42,1.42,0,0,0,12,2.69h0A1.57,1.57,0,0,0,10.14,1.16Z"
                  className="spinner_P7sC"
                />
              </svg>
            )}

            {userId.input && (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="#47ee40"
                className="bi bi-arrow-right-circle"
                viewBox="0 0 16 16"
                onClick={handleGenerateOTP}
              >
                <path
                  fillRule="evenodd"
                  d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8m15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z"
                />
              </svg>
            )}
            <input
              type="text"
              placeholder="Mobile No./Loan App Id"
              className="form-control mt-2"
              name="mobile_number"
              disabled={
                credentials.mobile_number === "" ? false : !userId.input
              }
              id="mobile_number"
              value={inputEmailValue}
              style={{ fontSize: "10px", padding: "2px" }}
              onChange={handleEmailOnChange}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  handleGenerateOTP();
                }
              }}
            />
          </div>
          <p className="loginAlert">{msgDisplay}</p>
          <div className="logininputholders" style={{ display: `${otpBox}` }}>
            {otpView.spin && (
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.14,1.16a11,11,0,0,0-9,8.92A1.59,1.59,0,0,0,2.46,12,1.52,1.52,0,0,0,4.11,10.7a8,8,0,0,1,6.66-6.61A1.42,1.42,0,0,0,12,2.69h0A1.57,1.57,0,0,0,10.14,1.16Z"
                  className="spinner_P7sC"
                />
              </svg>
            )}

            {otpView.input && (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="#47ee40"
                className="bi bi-arrow-right-circle"
                viewBox="0 0 16 16"
                onClick={handleValidateOTP}
              >
                <path
                  fillRule="evenodd"
                  d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8m15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z"
                />
              </svg>
            )}
            <input
              type="password"
              placeholder="Enter the received OTP"
              className="form-control mt-2"
              name="password"
              style={{ fontSize: "10px", padding: "2px" }}
              disabled={credentials.password === "" ? false : !otpView.input}
              id="otp"
              value={credentials.password}
              onChange={handlePasswordOnChange}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  handleValidateOTP();
                }
              }}
            />
            <p className="loginAlert">{otpDisplay}</p>
          </div>
        </div>
        <div>
          <div className="modal" style={{ display: `${show}` }}>
            <div className="modal-content">
              <div className="modal-header">
                <h5>Terms of Service</h5>
                <p style={{ fontSize: "10px" }}>
                  *Please scroll to the bottom of the “Terms of Service” to
                  continue to use our H.AI (Hero Artificial Intelligence)
                </p>
              </div>
              <div className="modal-body">
                <p>
                  Hero Fincorp Limited website(s) on
                  https://www.herofincorp.com/ and H.AI chatbot (hereinafter
                  collectively referred to as “Digital Platforms”) are provided
                  by Hero Fincorp Limited (“HFCL”) and may be used for
                  informational and transactional purposes only. Any person who
                  has been provided access to use the Digital Platforms by HFCL
                  subject to accepting the Terms of Service of use of such
                  Digital Platforms is referred to as ‘User’. The User has
                  complete discretion to use these platforms as provided by
                  HFCL. The usage of the Digital platforms constitutes an
                  agreement to abide by the Terms of Service and conditions set
                  forth hereinbelow.
                </p>
                <ol type="1">
                  <li>
                    <b>Description of Service</b>
                    <p>
                      HFCL currently provides Users with information on details
                      about Loan account and provides an array of self-servicing
                      features. Users can access their Loan Details, Request
                      Loan Related Document, New Loan Enquiry, Online Payment
                      Collection, Loan Closure Process, Customer Details
                      Updation Process and other allied services in regional
                      languages (hereinafter referred to as “Services”). Users
                      can also manage their raise service requests for their
                      queries and avail host of self-servicing features via
                      these Digital Platforms. Users understand and agree that
                      HFCL is providing such Services on a best effort basis
                      with no warranty whatsoever.
                    </p>
                  </li>
                  <li>
                    <b>Accuracy of information</b>
                    <p>
                      The User undertakes to provide accurate and complete
                      information wherever required and shall be solely
                      responsible for the correctness and completeness of
                      information provided by him/ her to HFCL at all times,
                      including, but not limited to, for the purposes of
                      availing of the Service. HFCL shall not be liable for
                      consequences arising out of erroneous information supplied
                      by the User. If the User suspects that there is an error
                      in the information supplied by HFCL to him/ her, he/ she
                      shall advise HFCL as soon as possible. HFCL will endeavour
                      to correct the error wherever possible on a best effort
                      basis. While HFCL will take all reasonable steps to ensure
                      the accuracy of the information supplied to the User, HFCL
                      shall not be liable for any inadvertent error, which
                      results in the providing of inaccurate information.
                    </p>
                    <p>
                      HFCL maintains this website for the User’s information,
                      usage, education and communication. The User may download
                      material displayed on the website for non-commercial and
                      personal use only, provided that the User retains all
                      copyright and other proprietary notices contained on the
                      materials. The User may not, however, distribute, modify,
                      transmit, reuse, report, or use the contents of the
                      website for public or commercial purposes, including the
                      text, images, audio, and video without HFCL ‘s prior
                      written permission. The materials displayed on the website
                      may contain inaccuracies and typographical errors. HFCL
                      does not warrant the accuracy or completeness of the
                      materials, advice, opinion, statement, or other
                      information displayed or distributed through the website
                      and reserves the right to correct any errors or omissions
                      in any portion of the website without any notice.
                    </p>
                  </li>
                  <li>
                    <b>Disclaimer</b>
                    <p>
                      HFCL makes no guarantee as to the accuracy or quality of
                      information published and/or archived on the Digital
                      Platform. All information provided through the Digital
                      Platform regarding the products and services provided by
                      the HFCL is subject to change without prior notice.
                      Reasonable efforts are taken to ensure the accuracy and
                      integrity of all information provided here, but HFCL shall
                      not be responsible for any misprints, out-of-date
                      information, technical snags or errors. HFCL makes no
                      warranty, express or implied, or assumes any legal
                      liability or responsibility for the accuracy or
                      completeness of any information contained or provided
                      through the Digital Platform.
                    </p>
                  </li>
                  <li>
                    <b>Limitation of Liability</b>
                    <ol type="a">
                      <li>
                        <p>
                          HFCL shall not be responsible for any failure on the
                          part of the User to utilize the Service due to the
                          User not being within the geographical range within
                          which the Service is offered.
                        </p>
                      </li>
                      <li>
                        <p>
                          HFCL specifically disclaims any liability for any
                          direct, indirect, incidental, consequential, or
                          special damages arising out of or in any way connected
                          with access to or use of the Digital platforms
                          including but not limited to liability associated with
                          any viruses/malware which may infect a User's Digital
                          device/ equipment.
                        </p>
                      </li>
                      <li>
                        <p>
                          HFCL will not be responsible to the User for any loss
                          of profits, loss of revenues, loss of business, loss
                          of opportunity, loss of or damage to data, business
                          interruption, property damage or any other commercial
                          damages or any loss arising from a cause beyond our
                          reasonable control, whether direct or indirect losses.
                        </p>
                      </li>
                      <li>
                        The User further agrees that HFCL shall not be liable
                        if:
                        <ol type="i">
                          <li>
                            <p>
                              The User has breached any of the terms and
                              conditions, contained herein; or
                            </p>
                          </li>
                          <li>
                            <p>
                              The User has contributed to, or the loss is a
                              result of failure on part of the User to advise
                              HFCL within a reasonable time about unauthorized
                              access of or erroneous transactions or breach of
                              data by use of the Service; or
                            </p>
                          </li>
                          <li>
                            <p>
                              Any unauthorized use of the User's loan account
                              details, OTPs, PIN/ password or mobile phone
                              number or for any fraudulent, duplicate or
                              erroneous instructions given by use of the User's
                              loan account details, OTPs, PIN/ password or
                              mobile phone number.
                            </p>
                          </li>
                          <li>
                            <p>
                              Acting in good faith on any instructions received
                              by HFCL from or on behalf of the User in relation
                              to the Service.
                            </p>
                          </li>
                          <li>
                            <p>
                              Error, default, delay or inability of HFCL to act
                              on all or any of the instructions given by the
                              User.
                            </p>
                          </li>
                          <li>
                            <p>
                              Loss of any information/instructions in
                              transmission.
                            </p>
                          </li>
                        </ol>
                        <li>
                          <p>
                            The User acknowledges that HFCL shall endeavour to
                            provide the Service on a best effort basis and the
                            User shall not hold HFCL liable for non-availability
                            of the Service or non-performance by service
                            providers (if any) engaged by HFCL or any loss or
                            damage caused to the User as a result of use of the
                            Service. HFCL shall not be liable in any manner to
                            the User in connection with the use of the Service.
                          </p>
                        </li>
                        <li>
                          <p>
                            The User understands that the Services regarding the
                            Digital Platforms may not be available due to any
                            planned maintenance or any other technical issues.
                            HFCL on a best effort basis will try to keep Users
                            informed of any planned maintenance regarding the
                            Digital Platforms, however, HFCL will not be liable
                            for any claim, loss, or damage that any User may
                            face due to his / her inability to access the
                            Digital Platforms due to any downtime of the Digital
                            Platform caused due to any planned maintenance or
                            technical issue.
                          </p>
                        </li>
                        <li>
                          <p>
                            HFCL shall not be held responsible for the
                            confidentiality, secrecy and security of the loan
                            account information being sent through the Digital
                            Platforms. The User agrees that the access to the
                            Digital Platforms shall be only through the
                            instruments which are controlled and operated by the
                            User and any transaction which originates from the
                            same, whether initiated by the User or not, shall be
                            deemed to have originated from the User.
                          </p>
                        </li>
                        <li>
                          <p>
                            The User shall not interfere with or misuse in any
                            manner whatsoever the Service and in the event of
                            any damage due to improper or fraudulent use by the
                            User, the User shall be liable for damages to HFCL,
                            for such amounts as may be decided by HFCL in its
                            sole judgement. This right of HFCL shall be in
                            addition to ay rights conferred available with HFCL,
                            in this document or otherwise.
                          </p>
                        </li>
                        <li>
                          <p>
                            The User hereby agrees, confirms and undertakes that
                            any information provided on the Digital Platform
                            shall be only for general information use, and shall
                            not amount to any evidence in the court of law,
                            pursuant to any law in force in in India, or outside
                            India. The User hereby waives, to the fullest extent
                            permissible by law, to admit any materials,
                            information, graphs, tables, or any data available
                            on our platform as evidence in any court of law,
                            tribunal or authority.
                          </p>
                        </li>
                        <li>
                          <p>
                            The User is solely responsible for protecting
                            his/her loan account details, sensitive information,
                            OTP, PIN/ password or mobile phone number and any
                            other password that may be issued by HFCL for the
                            use of the Service.
                          </p>
                        </li>
                      </li>
                    </ol>
                  </li>
                  <li>
                    <b> Indemnity</b>
                    <p>
                      {" "}
                      In consideration of HFCL providing the Service, the User,
                      at his own expense, agrees to indemnify, defend and hold
                      harmless, HFCL, its directors and employees,
                      representatives, agents and/or the affiliates, as the case
                      may be, against all losses, damages, expenses, actions,
                      claims, demands and proceedings whatsoever, that HFCL may
                      incur, sustain, suffer or be put to at any time as a
                      consequence of
                    </p>
                    <ol type="a">
                      <li>
                        <p>
                          Acting on or omitting or refusing to act on any
                          instructions given by the User or otherwise for use of
                          the Service.
                        </p>
                      </li>
                      <li>
                        <p>
                          The User permitting any third parties to use the
                          Service.
                        </p>
                      </li>
                      <li>
                        <p>
                          The User permitting any other person to have access to
                          his any device through which the Service is being
                          availed by the User or as a consequence of leaving the
                          mobile phone or any such other device through which
                          the Services are availed as unattended or loss of such
                          device.
                        </p>
                      </li>
                    </ol>
                  </li>
                  <li>
                    <b>Miscellaneous</b>
                    <ol type="a">
                      <li>
                        <p>
                          <u>Modifications: </u> HFCL reserves the right at any
                          time and from time to time to modify or discontinue,
                          temporarily or permanently, the Services provided by
                          its Digital platforms without notice. HFCL shall not
                          be liable to the User or to any third party for any
                          modification, suspension, or discontinuance of the
                          Service.
                        </p>
                      </li>
                      <li>
                        <p>
                          <u>Termination: </u>Notwithstanding anything contained
                          herein, in the event User violates any term or if HFCL
                          has grounds to believe that the User has violated any
                          terms herein, HFCL may terminate the access to the
                          Service or any part thereof without waiving any other
                          rights contemplated herein.
                        </p>
                      </li>
                      <li>
                        <p>
                          <u>Dealings with Third Parties: </u>The User’s
                          correspondence or business dealings with any third
                          party (if any), introduced to the User through this
                          Service, are solely between the User and such third
                          party at the User’s sole risk, peril and consequence.
                          The User are strongly advised to exercise the User’s
                          independent diligence before dealing with any such
                          third party.
                        </p>
                      </li>
                      <li>
                        <p>
                          <u>Governing Law Jurisdiction: </u>The relationship
                          between User and HFCL shall be governed by the laws of
                          India. User and HFCL hereby agree to submit to the
                          exclusive jurisdiction of courts at Delhi.
                        </p>
                      </li>
                    </ol>
                  </li>
                </ol>
              </div>
              <div className="modal-footer">
                <p>
                  I have read through the Terms of Service for use of Digital
                  Platforms as provided above by HFCL and I provide my express
                  consent and{" "}
                  <button
                    className="btn btn-primary btn-sm m-0 p-0"
                    type="button"
                    onClick={handleClose}
                  >
                    Agree
                  </button>{" "}
                  to the Terms of Service for use of Digital Platform.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginModule;
