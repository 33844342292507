import React, { useEffect, useState } from "react";
import botimage from "../img/bot.jpg";
import userimage from "../img/guy.jpg";
import LoginModule from "./LoginModule";
import PddUpdateForm from "./PddUpdateForm";

const Response = ({
  message: { isBot, query,showloginForm,pddForm, timestamp },
  sendtoAPI,
  setIsTnc,
  setMessages,
  apiResponse,
  setUseMessage,
  questionAsked,
  baseurl
}) => {
  
  const seemorebutton = isBot && query?.length >= 100;
  const [expanded, setExpanded] = useState(false);
  const toggleExpanded = () => {
    setExpanded(!expanded);
  };
  // Function to render clickable link if exists
  const renderClickableLink = (text) => {
    const linkRegex = /(https?:\/\/[^\s()]+)/g; 
    const parts = text.split(linkRegex); // Split text by URLs
  
    return parts.map((part, index) => {
      if (part.match(linkRegex)) {
        // If part is a link
        const link = part.replace(/[\[\]\(\)]/g, ''); // Remove brackets from the link
        return (
          <a key={index} href={link} target="_blank" rel="noopener noreferrer">
           Click Here
          </a>
        );
      } else {
        // If part is plain text
        // Remove the "Click Here" text
        const withoutClickHere = part.replace(/(Click Here|\[Click Here\])/g, '');
        // Preserve line breaks and add spacing
        const lines = withoutClickHere.split('\n').map((line, i) => <span key={i}>{line}<br/></span>);
        return <span key={index}>{lines}<br/></span>;
      }
    });
  };
  
  
  
{/* query.includes("not authenticated") ||
        query.includes("not logged in") ||
        query.includes("authenticated") ||
        query.includes("authenticate") ||
        query.includes("login") ||
        query.includes("log in") */}
  return (
    <div className={`message ${isBot ? "left" : "right"}`}>
      {/* <img src={isBot ? botimage : userimage} alt="Avatar" className="avatar" /> */}
      {isBot && (showloginForm) ? (
            <LoginModule sendtoAPI={sendtoAPI} setUseMessage={setUseMessage} setIsTnc={setIsTnc} questionAsked={questionAsked} baseurl={baseurl}/>
        ) :(pddForm)  ? (
            <PddUpdateForm baseurl={baseurl} query={query} setMessages={setMessages}/>
        
        ) :  (
          <div className="text_wrapper d-flex" >
          <div className="messageContent" style={{ maxHeight: isBot?(expanded ? 'none' : '50px'): "fit-content", overflow: 'hidden' }}>
            <div className="text">{renderClickableLink(query)}</div>
          </div>
          {seemorebutton && !expanded && (
        <div className="seemoretext">
          <p className="p-0 m-0" onClick={toggleExpanded} style={{cursor:"pointer" }}>
            See More
          </p>
        </div>
      )}
          </div>
        )}
        <div className={`message ${isBot ? "left" : "right"}`}>
        
            <div className="timestamp" style={{textAlign:isBot ? "left" : "right"}}>{timestamp}</div>
        </div>
    </div>
  );
};

export default Response;