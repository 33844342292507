import "./App.css";
import React, { useEffect, useState } from 'react';
import ChatScreen from "./components/ChatScreen";
import { v4 as uuidv4 } from 'uuid';

function App() {
  const [progress, setProgress] = useState(0);
  const [lastActivity, setLastActivity] = useState(Date.now());
  const [userimage, setUserimage] = useState({
    bot: "block",
    chat: "none",
  });
  const [logout, setLogout] = useState(false);
  // const baseurl = "https://hfcl-func-app-uat-test3.azurewebsites.net/api/";
  const baseurl = "https://hfcl-genai-apim-cin-001-prod.azure-api.net/chat/api/";
  // const baseurl = "https://hfcl-genai-apim-cin-001-uat.azure-api.net/chat/api/";

 const getSessionId= async()=>{
  let headersList = {
    "Accept": "*/*",
   }
   
   let response = await fetch(baseurl+"root", { 
     method: "GET",
     headers: headersList
   });
   
   let data = await response.json();
   localStorage.setItem("sessionIdGen", data.response);
   localStorage.setItem("sessionIdGuest", data.response);
 }
  useEffect(() => {
    localStorage.removeItem("sessionIdGen")
    localStorage.removeItem('SessionIdGuestUser')
    getSessionId()

  }, []);

  return (
    <>
      {localStorage.getItem("sessionIdGen")!==null && <ChatScreen baseurl={baseurl}/>}
    </>
  );
}

export default App;
