import React, { useEffect, useState } from "react";

const TIMEOUT_DURATION = 5 * 60; // 5 minutes in seconds
// const TIMEOUT_DURATION =10; // 5 minutes in seconds

function Timer({ onTimeout, lastActivity }) {
  const [timeRemaining, setTimeRemaining] = useState(TIMEOUT_DURATION);
  const [hasTimedOut, setHasTimedOut] = useState(false);

  useEffect(() => {
    if (lastActivity) {
      setTimeRemaining(TIMEOUT_DURATION);
      setHasTimedOut(false);
    }
  }, [lastActivity]);

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeRemaining(prevTime => (prevTime > 0 ? prevTime - 1 : 0));
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (timeRemaining === 0 && !hasTimedOut) {
      onTimeout();
      setHasTimedOut(true);
    }
  }, [timeRemaining, onTimeout, hasTimedOut]);

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };

  return (
    <div className="timer">
      Session Timeout in: {formatTime(timeRemaining)}
    </div>
  );
}

export default Timer;
