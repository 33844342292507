import React, { useState } from "react";
import Response from "./Response";


const MessagesHolder = ({ messages,setUseMessage, setMessages, sendtoAPI,questionAsked,baseurl,apiResponse,messagesEndRef }) => {
  const [isTnc,setIsTnc] = useState(false);

  return (
    <div className="messages">
    
      {messages.map((message, index) => (
        <Response
          key={"component" + index}
          message={message}
          setMessages={setMessages}
          sendtoAPI={sendtoAPI}
          showRecommendation={true}
          questionAsked={questionAsked}
          baseurl={baseurl}
          setUseMessage={setUseMessage}
          setIsTnc={setIsTnc}
          apiResponse={apiResponse}
        />
      ))}
      <div ref={messagesEndRef} />
    </div>
  );
};

export default MessagesHolder;
