import React from 'react'

const Inputbar = ({inputBox,useMessage,handleSendButtonClick,handleInputChange,inputValue}) => {
  return (
    <>
        {inputBox && (
            <div className="bottom_wrapper" style={{ position: "relative" }}>
            <input
                id="msg_input"
                value={inputValue}
                placeholder="Type your query here..."
                disabled={useMessage.spin}
                onChange={handleInputChange}
                onKeyDown={(event) => {
                  if (inputValue.trim().length > 0 && !/^[^\w\s]+$/.test(inputValue) && event.key === "Enter") {
                    handleSendButtonClick();
                  }
                }}
              />
             
              {inputValue.trim().length > 0 && !/^[^\w\s]+$/.test(inputValue) && <div className="sendButtonDiv" onClick={handleSendButtonClick}>
                {useMessage.input&&( <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="#47ee40"
                  className="bi bi-send-fill"
                  viewBox="0 0 16 16"
                  
                >
                  <path d="M15.964.686a.5.5 0 0 0-.65-.65L.767 5.855H.766l-.452.18a.5.5 0 0 0-.082.887l.41.26.001.002 4.995 3.178 3.178 4.995.002.002.26.41a.5.5 0 0 0 .886-.083zm-1.833 1.89L6.637 10.07l-.215-.338a.5.5 0 0 0-.154-.154l-.338-.215 7.494-7.494 1.178-.471z" />
                </svg>)}
                </div>}
                {useMessage.spin && (
                  <div className="sendButtonDiv">
                  <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M10.14,1.16a11,11,0,0,0-9,8.92A1.59,1.59,0,0,0,2.46,12,1.52,1.52,0,0,0,4.11,10.7a8,8,0,0,1,6.66-6.61A1.42,1.42,0,0,0,12,2.69h0A1.57,1.57,0,0,0,10.14,1.16Z" className="spinner_P7sC"/></svg>
                  </div>
              )}
              
              
            
            </div>
          )}
    </>
  )
}

export default Inputbar
