import React, { useState } from "react";

const PddUpdateForm = ({ baseurl, setMessages, query }) => {
  const [chassisNo, setChassisNo] = useState("");
  const [engineNo, setEngineNo] = useState("");
  const [vehicleNo, setVehicleNo] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [detailsDisplay, setDetailsDisplay] = useState(false);
  const [submit, setSubmit] = useState({
    input: true,
    spin: false,
  });
  const handleSubmitData = async () => {
    if (!chassisNo || !engineNo || !vehicleNo || !selectedFile) {
      setDetailsDisplay(true);
      return; // Stop further execution
    } else {
      setSubmit({
        input: false,
        spin: true,
      });
      try {
        const formData = new FormData();
        formData.append("chassis_no", chassisNo);
        formData.append("engine_no", engineNo);
        formData.append("vehicle_number", vehicleNo);
        formData.append("image", selectedFile);
        formData.append("session_id", localStorage.getItem("sessionIdGen"));
        const url = baseurl + "noc_with_updated_vehicle_details";
        let response = await fetch(url, {
          method: "POST",
          body: formData,
        });
        let responseData = await response.json();
        setSubmit({
          input: true,
          spin: false,
        });
        setMessages((prevMessages) => [
          ...prevMessages,
          {
            query: responseData.output,
            isBot: true,
            timestamp: new Date().toLocaleTimeString([], {
              hour: "2-digit",
              minute: "2-digit",
              hour12: false,
            }),
            recommendedQuestion: responseData.follow_up_questions
              ? responseData.follow_up_questions
              : null,
          },
        ]);
      } catch (error) {
        console.error("Error:", error);
      }
    }
  };

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
    setDetailsDisplay(false);
  };

  return (
    <div
      className="text_wrapper p-2"
      style={{ marginRight: "100px", width: "350px" }}
    >
      <div className="text">{query}</div>
      <div className="logincontainer">
        <input
          className="form-control form-control-sm mb-2"
          type="text"
          style={{ fontSize: "10px", padding: "2px" }}
          placeholder="Chassis Number"
          value={chassisNo}
          onChange={(e) => {
            setChassisNo(e.target.value);
            setDetailsDisplay(false);
          }}
        />
        <input
          className="form-control form-control-sm mb-2"
          type="text"
          style={{ fontSize: "10px", padding: "2px" }}
          placeholder="Engine Number"
          value={engineNo}
          onChange={(e) => {
            setEngineNo(e.target.value);
            setDetailsDisplay(false);
          }}
        />
        <input
          className="form-control form-control-sm mb-2"
          type="text"
          placeholder="Vehicle Number"
          style={{ fontSize: "10px", padding: "2px" }}
          value={vehicleNo}
          onChange={(e) => {
            setVehicleNo(e.target.value);
            setDetailsDisplay(false);
          }}
        />
        <div className="input-group mb-3">
          <input
            type="file"
            style={{ fontSize: "10px", padding: "2px" }}
            className="form-control"
            id="inputGroupFile02"
            onChange={handleFileChange}
          />
        </div>
        {detailsDisplay && <p>Please Fill all the required details</p>}
        {submit.input && (
          <button
            style={{ fontSize: "10px", padding: "2px" }}
            className="btn btn-outline-primary"
            onClick={handleSubmitData}
          >
            Submit
          </button>
        )}
        {submit.spin && (
          <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M10.14,1.16a11,11,0,0,0-9,8.92A1.59,1.59,0,0,0,2.46,12,1.52,1.52,0,0,0,4.11,10.7a8,8,0,0,1,6.66-6.61A1.42,1.42,0,0,0,12,2.69h0A1.57,1.57,0,0,0,10.14,1.16Z" className="spinner_P7sC"/></svg>
        )}
      </div>
    </div>
  );
};

export default PddUpdateForm;
